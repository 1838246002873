<template>
  <v-footer>
    <v-tooltip v-model="show" right>
      <template v-slot:activator="{ on }">
        <div style="font-size: 12px;" @mouseover="show = true" @mouseout="show = false">
          &copy; {{ new Date().getFullYear() }} | Todos os direitos reservados.
        </div>
      </template>
      v.{{appVersion}}
    </v-tooltip>
    
    <v-spacer></v-spacer>
  </v-footer>
</template>

<script>
export default {
  name: 'BrFooter',
  data () {
    return {
      show: false
    }
  },
  computed: {
    appVersion () {
      return process.env.VUE_APP_VERSION
    }
  }
}
</script>

<style lang="scss" scoped>
  .theme--light.v-footer {
    background-color: #21b6ff;
    color: white;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
  }
</style>